import { useState } from 'react';
import { CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import NavBar from './components/common/NavBar';
import HomePage from './pages/HomePage';

function App() {
  const [activeSectionId, setActiveSectionId] = useState<string>('homepage');
  const [scrolling, setScrolling] = useState<boolean>(false);

  return (
    <>
      <CssBaseline />
      <NavBar
        {...{
          scrolling,
          setScrolling,
          activeSectionId,
          onActiveSectionChange: setActiveSectionId,
        }}
      />
      <main role="main">
        <Routes>
          <Route
            path="/"
            element={
              <HomePage
                {...{ scrolling, activeSectionId, setActiveSectionId }}
              />
            }
          />
        </Routes>
      </main>
    </>
  );
}

export default App;

