import React, { FC } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import popupImage from '../../assets/images/Popup.jpg';

interface PopupProps {
  open: boolean;
  onClose: () => void;
}

const Popup: FC<PopupProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          boxShadow: 'none', // Supprime la bordure d'ombre
          border: 'none', // Supprime la bordure
        },
      }}
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
        <img src={popupImage} alt="Popup Image" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
